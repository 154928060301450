export const VIBE_DOMAIN_RESOURCE_TYPE = 'VIBE_DOMAIN';
export const VIBE_VIEW_OPERATION = 'View';

export enum VibeDomainResources {
  MDM = 'MDM_Domain',
  RTWO = 'RTWO_Domain',
  FORECASTING = 'Forecasting_Domain',
  PLANNING = 'Planning_Domain',
  SCHEDULING = 'Scheduling_Domain',
  REPORTS_AND_ANALYTICS = 'ReportsAndAnalytics_Domain',
  GEM = 'GEM_DOMAIN',
  CONFIGURATION = 'Configurations_Domain',
  NOTIFICATION_CENTER = 'NotificationCenter_Domain',
  GEN_AI = 'Gen_AI_Domain'
}
