export const HOME_PAGE = '/';
export const ACCESS_DENIED_PAGE = '/access-denied';
export const ERROR_PAGE = '/error';
export const RTWO_BASE_PATH = '/realtime';
export const FORECASTING_BASE_PATH = '/forecasting';
export const GEN_AI_BASE_PATH = '/gen-ai';
export const PLANNING_BASE_PATH = '/planning';
export const SCHEDULING_BASE_PATH = '/scheduling';
export const REPORT_BASE_PATH = '/reportsandanalytics';
export const GEM_BASE_PATH = '/gem';
export const CONFIGURATION_BASE_PATH = '/configurations';
export const CDM_BASE_PATH = '/cdm';
export const NOTIFICATION_CENTER_BASE_PATH = '/notification';
export const BWGA_USER_MOVEMENTS_PAGE_PATH = '/realtime/levers/bwga/movements/review';
export const REALTIME_SKILL_VIEW_PAGE = 'dashboard/skills-view';
