import { SideNavigation, SideNavigationProps, Spinner } from '@amzn/awsui-components-react-v3';
import {
  getForecastingPagePaths,
  getForecastingSideNavSectionV3
} from '@amzn/wfo-forecasting-components/dist/modules/nav-items';
import {
  getGenAIPagePaths,
  getGenAISideNavSectionV3
} from '@amzn/wfo-ai-components/dist/modules/nav-items';
import { listAuthorizedResources } from '@wfo/authorization-components';
import {
  getGEMPagePaths,
  getGEMSideNavSectionV3
} from '@amzn/wfo-grasp-engagement-module-components/dist/modules/nav-items';
import {
  getConfigurationsPagePaths,
  getConfigurationsSidenavSectionV3
} from '@wfo/configurations-components/dist/modules/nav-items';
import { getMDMPagePaths, getMdmSideNavSectionV3 } from '@wfo/mdm-components/dist/modules/nav-items';
import { getPlanningPagePaths, getPlanningSideNavSectionV3 } from '@wfo/planning-components/dist/modules/nav-items';
import { getRtwoPagePaths, getRtwoSidenavSectionV3 } from '@wfo/rtwo-components/dist/modules/nav-items';
import { getReportsPagePaths, getReportsSideNavSectionV3 } from '@wfo/report-components/dist/modules/nav-items';
import {
  getScheduleManagementPagePaths,
  getScheduleManagementSidenavSectionV3
} from '@wfo/schedule-management-components/dist/modules/nav-items';
import {
  getNotificationCenterPagePaths,
  getNotificationCenterSidenavSection
} from '@wfo/notification-center-components/dist/modules/nav-items';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';
import * as pagePaths from '../../constants/page-paths';
import { VibeDomainResources, VIBE_DOMAIN_RESOURCE_TYPE, VIBE_VIEW_OPERATION } from '../../constants/rbac-resources';
import logger from '../../utilities/logger';

interface SidenavDetails {
  section: SideNavigationProps.Section | Promise<SideNavigationProps.Section>;
  sortPriority: number;
}

const pathsToMatch: string[] = [
  getForecastingPagePaths(pagePaths.FORECASTING_BASE_PATH),
  getGenAIPagePaths(pagePaths.GEN_AI_BASE_PATH),
  getPlanningPagePaths(pagePaths.PLANNING_BASE_PATH),
  getMDMPagePaths(pagePaths.CDM_BASE_PATH),
  getRtwoPagePaths(pagePaths.RTWO_BASE_PATH),
  getScheduleManagementPagePaths(pagePaths.SCHEDULING_BASE_PATH),
  getReportsPagePaths(pagePaths.REPORT_BASE_PATH),
  getGEMPagePaths(pagePaths.GEM_BASE_PATH),
  getConfigurationsPagePaths(pagePaths.CONFIGURATION_BASE_PATH),
  getNotificationCenterPagePaths(pagePaths.NOTIFICATION_CENTER_BASE_PATH)
].flat();

const Navigation: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation(['common', 'side-nav']);
  const [activeHref, setActiveHref] = useState(pathname);

  useEffect(() => {
    const matchedPath = pathsToMatch.find(path => !!matchPath(pathname, { path }));
    setActiveHref(matchedPath || pathname);
  }, [pathname]);

  const domainSidenavMap: Record<VibeDomainResources, SidenavDetails> = useMemo(() => {
    return {
      [VibeDomainResources.FORECASTING]: {
        section: getForecastingSideNavSectionV3(pagePaths.FORECASTING_BASE_PATH),
        sortPriority: 1
      },
      [VibeDomainResources.PLANNING]: {
        section: getPlanningSideNavSectionV3(pagePaths.PLANNING_BASE_PATH),
        sortPriority: 2
      },
      [VibeDomainResources.MDM]: {
        section: getMdmSideNavSectionV3(pagePaths.CDM_BASE_PATH),
        sortPriority: 3
      },
      [VibeDomainResources.CONFIGURATION]: {
        section: getConfigurationsSidenavSectionV3(pagePaths.CONFIGURATION_BASE_PATH),
        sortPriority: 4
      },
      [VibeDomainResources.RTWO]: {
        section: getRtwoSidenavSectionV3(pagePaths.RTWO_BASE_PATH, t),
        sortPriority: 5
      },
      [VibeDomainResources.SCHEDULING]: {
        section: getScheduleManagementSidenavSectionV3(pagePaths.SCHEDULING_BASE_PATH, t),
        sortPriority: 6
      },
      [VibeDomainResources.REPORTS_AND_ANALYTICS]: {
        section: getReportsSideNavSectionV3(pagePaths.REPORT_BASE_PATH),
        sortPriority: 7
      },
      [VibeDomainResources.NOTIFICATION_CENTER]: {
        section: getNotificationCenterSidenavSection(pagePaths.NOTIFICATION_CENTER_BASE_PATH, t),
        sortPriority: 9
      },
      [VibeDomainResources.GEM]: {
        section: getGEMSideNavSectionV3(pagePaths.GEM_BASE_PATH),
        sortPriority: 10
      },
      [VibeDomainResources.GEN_AI] : {
        section: getGenAISideNavSectionV3(pagePaths.GEN_AI_BASE_PATH),
        sortPriority: 11
      }
    };
  }, [t]);

  const {
    loading,
    error,
    value: sidenavItems = []
  } = useAsync(async () => {
    try {
      const listAuthorizedResourcesRequest = {
        resourceType: VIBE_DOMAIN_RESOURCE_TYPE,
        operation: VIBE_VIEW_OPERATION
      };

      const resourceOperations = await Promise.all([
        listAuthorizedResources({
          ...listAuthorizedResourcesRequest,
          GICall: true
        })
      ]).then(responseList => {
        return responseList.map(response => response.resourceOperations);
      });

      const sidenavItems: SideNavigationProps.Item[] = await Promise.all(
        resourceOperations
          .flat()
          .map(resOp => resOp.resource.resourceName as VibeDomainResources)
          .filter(res => res in domainSidenavMap)
          .sort((resA, resB) => domainSidenavMap[resA].sortPriority - domainSidenavMap[resB].sortPriority)
          .map(res => domainSidenavMap[res].section)
      );
      const divider: SideNavigationProps.Divider = { type: 'divider' };
      const sidenavItemsWithDivider = sidenavItems.flatMap(section => [section, divider]);

      return sidenavItemsWithDivider;
    } catch (err) {
      logger.error('SideNavItemsError', err);
      throw err;
    }
  }, [domainSidenavMap]);

  const navigate = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
    if (!event.detail.external) {
      event.preventDefault();
      history.push(event.detail.href);
    }
  };

  const items: SideNavigationProps.Item[] = [
    ...sidenavItems,
    {
      type: 'link',
      text: t('vibe_settings'),
      href: '/settings'
    },
    {
      type: 'link',
      text: t('vibe_documentation'),
      href: 'https://w.amazon.com/bin/view/Vibe/',
      external: true
    },
    { type: 'divider' },
    {
      type: 'link',
      text: t('vibe_logout'),
      href: '/logout'
    }
  ];

  if (loading) {
    return (
      <div className="awsui-util-t-c awsui-util-mt-xl">
        <Spinner data-testid="spinner" />
      </div>
    );
  }

  if (error) {
    return <Redirect to={pagePaths.ERROR_PAGE} />;
  }

  return (
    <SideNavigation
      header={{ text: t('vibe_central_console'), href: '/' }}
      onFollow={navigate}
      items={items}
      activeHref={activeHref}
    />
  );
};

export default Navigation;
